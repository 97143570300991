<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список коллекций</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column" style="text-align: end">
        <b-button
          type="is-danger"
          @click="uploadCollections"
        >Обновить с Firebase</b-button>

        <b-button
          class="ml-3"
          @click="createCollection"
          type="is-primary"
          icon-right="plus"
        >Добавить коллекцию</b-button>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="collections"
      class="users-table"
    >
      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.name }}</span>
      </b-table-column>

      <b-table-column label="Колонки" v-slot="props">
        <span>{{ props.row.fields ? props.row.fields.map(field => field.name).join(', ') : '' }}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">

        <div>
          <b-tooltip label="Редактировать">
            <b-button class="edit_icon" @click="updateCollection(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить">
            <b-button
              @click="deleteCollection(props.row)"
              class="delete_icon">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>

      </b-table-column>
    </b-table>

    <OverlayLoader :show="isLoading"></OverlayLoader>
    <CollectionConfirmDeleteModal/>
    <CollectionDetailsModal/>
    <CollectionsUpload/>

  </div>
</template>

<script>
import OverlayLoader from "@/app/shared/components/app-loader/Loader";
import CollectionConfirmDeleteModal
  from '@/app/client/collections_settings/collections/shared/modals/confirmDeletionCollectionModal.vue';
import CollectionDetailsModal
  from '@/app/client/collections_settings/collections/shared/modals/collectionDetailsModal.vue';
import CollectionsUpload
  from '@/app/client/collections_settings/collections/shared/modals/confirmUploadCollectionModal.vue';

export default {
  name: "CollectionsList",
  components: {
    OverlayLoader,
    CollectionConfirmDeleteModal,
    CollectionDetailsModal,
    CollectionsUpload
  },
  data() {
    return {
    };
  },
  created() {
    this.getCollections();
  },
  computed: {
    collections() {
      return this.$store.getters.GET_COLLECTIONS;
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_COLLECTIONS_LOADING;
      },
      set(value) {
        this.$store.commit("SET_COLLECTIONS_LOADING", value);
      },
    },
    detailOpened: {
      get() {
        return this.$store.getters.IS_DETAIL_COLLECTION_MODAL;
      },
      set(value) {
        this.$store.commit("SET_DETAIL_COLLECTION_MODAL", value);
      },
    },
    idFirebase() {
      return this.$route.params.idFirebase;
    },
  },
  methods: {
    getCollections() {
      this.$store.dispatch("GET_COLLECTIONS", this.idFirebase).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    deleteCollection(collection) {
      this.$store.commit('SET_COLLECTIONS_DELETION_ID_COLLECTION', collection.id);
      this.$store.commit('SET_CONFIRM_DELETION_COLLECTION_MODAL', true);
    },
    createCollection() {
      this.$store.commit('SET_DEFAULT_CURRENT_COLLECTION');
      this.detailOpened = true;
    },
    updateCollection(collection) {
      this.detailOpened = true;
      this.$store.commit('SET_CURRENT_COLLECTION', collection);
    },
    uploadCollections() {
      this.$store.commit('SET_CONFIRM_UPLOAD_COLLECTIONS_MODAL', true);
    }
  },
};
</script>

<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
