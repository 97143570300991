<template>
  <v-dialog
    v-model="isOpen"
    max-width="700"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline mb-4">
        Вы точно хотите удалить эту коллекцию?
      </v-card-title>
      <v-card-subtitle>
        <h1 class="title is-2">Она удалится сразу и в <span class="error--text">Firebase</span></h1>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" @click="deleteCollection">Удалить</v-btn>
      </v-card-actions>

      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader.vue';

export default {
  name: 'confirmDeletionModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
    };
  },
  computed: {
    isOpen: {
      get(){
        return this.$store.getters.IS_CONFIRM_DELETION_COLLECTION_MODAL;
      },
      set(value){
        this.$store.commit('SET_CONFIRM_DELETION_COLLECTION_MODAL', value);
      }
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_COLLECTIONS_DELETION_LOADING;
      },
      set(value) {
        this.$store.commit('SET_COLLECTIONS_DELETION_LOADING', value);
      }
    },
    idFirebase() {
      return this.$route.params.idFirebase;
    },
    idCollection() {
      return this.$store.getters.ID_COLLECTION_TO_DELETE;
    }
  },
  methods: {
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.$store.commit('SET_COLLECTIONS_DELETION_ID_COLLECTION', null);
      this.isLoading = false;
      this.isOpen = false;
    },
    deleteCollection(){
      let data = {
        idCollection: this.idCollection,
        idFirebase: this.idFirebase
      }
      this.$store.dispatch('DELETE_COLLECTION', data)
        .then(() => {
          this.$toast.success('Коллекция была удалена');
          this.$store.dispatch("GET_COLLECTIONS", this.idFirebase);
        })
        .catch((error) => {
          if (error === 504) {
            this.$toast.error('Ошибка прокси');
          } else {
            this.$toast.error('Неизвестная ошибка');
          }
        }).finally(() => {
          this.close();
        });
    }
  },
};
</script>
