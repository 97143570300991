<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="isCreate">
            <h2 class="title is-3">Создать коллекцию</h2>
          </div>
          <div class="column" v-if="!isCreate">
            <h2 class="title is-3">Изменить коллекцию: {{ currentCollectionName }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentCollection.name"></b-input>
            </b-field>


            <div v-if="!isCreate || (!currentCollection.use_standard_fields && isCreate)">

              <div
                class="mt-6"
                style="
                align-items: end;
                display: flex;
                flex-direction: column;"
              >
                <b-button @click="addField" type="is-primary" size="is-small">
                  Добавить поле
                </b-button>
              </div>

              <b-field label="Поля" required>
                <b-table :data="currentCollection.fields" :striped="true" :hoverable="true">
                  <b-table-column field="name" label="Имя поля">
                    <template v-slot="props">
                      <b-input
                        v-model="props.row.name"
                        placeholder="Введите имя поля"
                        required
                      ></b-input>
                    </template>
                  </b-table-column>

                  <b-table-column field="type" label="Тип поля">
                    <template v-slot="props">
                      <b-select v-model="props.row.type">
                        <option value="string">String</option>
                        <option value="number">Number</option>
                        <option value="bool">Boolean</option>
                        <option value="timestamp">Timestamp</option>
                      </b-select>
                    </template>
                  </b-table-column>

                  <b-table-column label="Действия">
                    <template v-slot="props">
                      <b-button @click="removeField(props.row)" type="is-danger" size="is-small">
                        Удалить
                      </b-button>
                    </template>
                  </b-table-column>
                </b-table>
              </b-field>

              <p class="error--text" v-if="!isCreate">
                Не меняйте название полей, иначе прошлое поле будет удалено, а новое добавлено. Вы потеряете его значение
              </p>

            </div>

            <p class="help" v-if="!currentCollection.use_standard_fields">Например: field_1, field_2, field_3</p>

            <p class="error--text" v-if="isCreate">
              В вашу коллекцию будет добавлен документ с примером и выбранными полями.
              Это нужно потому что коллекция без документов не может существовать
            </p>

            <b-field v-if="isCreate">
              <b-checkbox v-model="currentCollection.use_standard_fields">Использовать стандартные поля</b-checkbox>
            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentCollection.id == null" @click="createCollection()">Добавить</v-btn>
        <v-btn text
               color="primary"
               v-if="!isCreate"
               @click="updateCollection()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';
import { toFormData } from '@/app/shared/services/helpers';

export default {
  name: 'CollectionDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      currentCollectionName: '',
      fieldsText: '',
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.currentCollectionName = this.currentCollection.name;
      }
    },
  },
  computed: {
    isCreate() {
      return this.currentCollection.id === null;
    },
    currentCollection: {
      get() {
        return this.$store.getters.CURRENT_COLLECTION;
      },
      set(value) {
        this.$store.commit('SET_CURRENT_COLLECTION', value);
      },
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_DETAIL_COLLECTION_MODAL_LOADING;
      },
      set(value) {
        this.$store.commit('SET_DETAIL_COLLECTION_MODAL_LOADING', value);
      },
    },
    isOpen: {
      get() {
        return this.$store.getters.IS_DETAIL_COLLECTION_MODAL;
      },
      set() {
        this.$store.commit('SET_DETAIL_COLLECTION_MODAL', false);
      },
    },
    idFirebase() {
      return this.$route.params.idFirebase;
    }
  },
  methods: {
    addField() {
      // Добавляет новое поле с пустым именем и типом 'string' по умолчанию
      this.currentCollection.fields.push({ name: '', type: 'string' });
    },
    removeField(field) {
      const index = this.currentCollection.fields.indexOf(field);
      if (index > -1) {
        this.currentCollection.fields.splice(index, 1);
      }
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    createCollection() {
      let data = {
        collection: this.currentCollection,
        firebase: this.idFirebase
      }
      this.$store.dispatch('CREATE_COLLECTION', data)
        .then(() => {
          this.$toast.success("Коллекция создана!");
          this.$store.dispatch('GET_COLLECTIONS', this.idFirebase);
          this.close();
        })
        .catch((error) => {
          if (error === 504) {
            this.$toast.error("Ошибка прокси");
          } else {
            this.$toast.error("Ошибка при создании коллекции");
          }
        });
    },
    updateCollection() {
      let data = {
        collection: this.currentCollection,
        firebase: this.idFirebase
      }
      this.$store.dispatch('UPDATE_COLLECTION', data)
        .then(() => {
          this.$toast.success("Коллекция изменена!");
          this.$store.dispatch('GET_COLLECTIONS', this.idFirebase);
          this.close();
        })
        .catch((error) => {
          if (error === 504) {
            this.$toast.error("Ошибка прокси");
          } else {
            this.$toast.error("Ошибка при изменении коллекции");
          }
        });
    },
    close() {
      this.currentCollection = {
        name: '',
        id: null,
        fields: [],
        use_standard_fields: false
      };
      this.isOpen = false;
      this.isLoading = false;
    },
  },
};
</script>

<style>
.full-width-upload {
  width: 100%;
  display: block;
}

.full-width-upload .section {
  width: 100%;
}

.full-width-upload .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
