<template>
  <v-dialog
    v-model="isOpen"
    max-width="700"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline mb-4 error--text">
        Вы готовы обновить ВСЕ коллекции с Firebase?
      </v-card-title>
      <v-card-subtitle>
        <h1 class="title is-2">Отменить это будет уже нельзя. Если есть совпадения по именам коллекций, колонки для них останутся</h1>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Закрыть</v-btn>
        <v-btn text color="primary" @click="uploadCollection">Обновить</v-btn>
      </v-card-actions>

      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader.vue';

export default {
  name: 'confirmUploadModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
    };
  },
  computed: {
    isOpen: {
      get(){
        return this.$store.getters.IS_CONFIRM_UPLOAD_COLLECTIONS_MODAL;
      },
      set(value){
        this.$store.commit('SET_CONFIRM_UPLOAD_COLLECTIONS_MODAL', value);
      }
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_COLLECTIONS_UPLOAD_LOADING;
      },
      set(value) {
        this.$store.commit('SET_COLLECTIONS_UPLOAD_LOADING', value);
      }
    },
    idFirebase() {
      return this.$route.params.idFirebase;
    },
  },
  methods: {
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.isLoading = false;
      this.isOpen = false;
    },
    uploadCollection(){
      this.$store.dispatch('UPLOAD_COLLECTIONS', this.idFirebase)
        .then(() => {
          this.$toast.success('Список коллекций был обновлён');
          this.$store.dispatch("GET_COLLECTIONS", this.idFirebase);
        })
        .catch((error) => {
          if (error === 504) {
            this.$toast.error('Ошибка прокси');
          } else {
            this.$toast.error('Неизвестная ошибка');
          }
        }).finally(() => {
          this.close();
        });
    }
  },
};
</script>
